import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import OpeningAnimation from './components/OpeningAnimation';
import Onboarding from './components/Onboarding';
import Landing from './components/Landing';
import ReferralCodesScreen from './components/ReferralCodesScreen';
const useAsyncError = () => {
    const [_, setError] = useState();
    return (e: Error) => {
        setError(() => {
            throw e;
        });
    };
};


class ErrorBoundary extends React.Component<{ children: React.ReactNode }, { hasError: boolean, error: Error | null }> {
    constructor(props: { children: React.ReactNode }) {
        super(props);
        this.state = { hasError: false, error: null };
    }

    static getDerivedStateFromError(error: Error) {
        return { hasError: true, error };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        console.error("Uncaught error:", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <div style={{ padding: '20px', color: 'red' }}>
                <h1>Oops! Something went wrong.</h1>
                <p>{this.state.error?.message}</p>
            </div>;
        }

        return this.props.children;
    }
}

const useInitData = (hasStarted: boolean) => {
    const [initData, setInitData] = useState<{
        initDataUnsafe: any;
        initData: any;
    } | null>(null);

    useEffect(() => {
        if (!hasStarted) return;

        let retryCount = 0;
        const maxRetries = 5;

        const initializeTelegramWebApp = () => {
            //console.log(`Attempt ${retryCount + 1} to initialize Telegram WebApp`);
            if (window.Telegram && window.Telegram.WebApp) {
                //console.log("Telegram WebApp found. Calling ready()...");
                window.Telegram.WebApp.ready();
                const webAppData = {
                    initDataUnsafe: window.Telegram.WebApp.initDataUnsafe,
                    initData: window.Telegram.WebApp.initData
                };
                //console.log("WebApp data:", webAppData);
                setInitData(webAppData);
            } else if (retryCount < maxRetries) {
                retryCount++;
                //console.log(`Telegram WebApp not found. Retrying in 1 second...`);
                setTimeout(initializeTelegramWebApp, 1000);
            } else {
                //console.log("Failed to initialize Telegram WebApp after maximum retries");
            }
        };

        initializeTelegramWebApp();
    }, [hasStarted]);

    return initData;
};

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

//console.log("App.tsx is being executed");

declare global {
    interface Window {
        Telegram: any;
    }
}

const App: React.FC = () => {
    const throwError = useAsyncError();
    const [currentScreen, setCurrentScreen] = useState<'loading' | 'onboarding' | 'landing' | 'referralCodes'>('loading');
    const [referralCodes, setReferralCodes] = useState<string[]>([]);
    const [isOnboardingComplete, setIsOnboardingComplete] = useState(false);
    const [isAnimationComplete, setIsAnimationComplete] = useState(false);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const initDataChecked = useRef(false);
    const initData = useInitData(true); // Always initialize


    //console.log("Current Screen:", currentScreen);
    //console.log("Is Onboarding Complete:", isOnboardingComplete);
    //console.log("Is Data Loaded:", isDataLoaded);

    const telegramInfo = initData?.initDataUnsafe?.user ? {
        chatId: initData.initDataUnsafe.user.id.toString(),
        username: initData.initDataUnsafe.user.username || 'Not available',
    } : null;

    //console.log("Telegram Info:", telegramInfo);

    useEffect(() => {
        if (initDataChecked.current) return;

       // console.log("Checking initData in useEffect...");
        if (initData === null) {
            //console.log("initData is null, waiting...");
        } else if (!initData.initDataUnsafe || !initData.initDataUnsafe.user) {
            //console.log("initData is available but user info is missing, throwing error...");
            throwError(new Error('Telegram user data is not available'));
        } else {
            //console.log("initData is available with user info:", initData.initDataUnsafe.user);
            initDataChecked.current = true;
        }
    }, [initData, throwError]);

    useEffect(() => {
        const fetchUserStatus = async () => {
            if (!telegramInfo || isDataLoaded || isFetching) return;

            setIsFetching(true);

            try {
                const response = await fetch(`${API_BASE_URL}/api/check-user-status?username=${telegramInfo.username}&chatID=${telegramInfo.chatId}`);

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                //console.log("User status response:", data);
                if (data.hasFollowed && data.hasTweeted) {
                    setIsOnboardingComplete(true);
                    setReferralCodes(data.refCodes || []);
                } else {
                    setIsOnboardingComplete(false);
                }
                setIsDataLoaded(true);
            } catch (error) {
                //console.error('Failed to fetch user status:', error);
                setIsDataLoaded(true);
                throwError(error instanceof Error ? error : new Error('An unknown error occurred'));
            } finally {
                setIsFetching(false);
            }
        };

        fetchUserStatus();
    }, [telegramInfo, isDataLoaded, isFetching, throwError]);

    const handleAnimationComplete = () => {
        //console.log("Animation complete");
        setIsAnimationComplete(true);
        if (isDataLoaded) {
            setCurrentScreen(isOnboardingComplete ? 'landing' : 'onboarding');
        }
    };

    useEffect(() => {
        if (isAnimationComplete && isDataLoaded) {
            setCurrentScreen(isOnboardingComplete ? 'landing' : 'onboarding');
        }
    }, [isAnimationComplete, isDataLoaded, isOnboardingComplete]);


    const handleOnboardingComplete = (codes: string[]) => {
        //console.log("Onboarding complete", codes);
        setReferralCodes(codes);
        setIsOnboardingComplete(true);
        setCurrentScreen('landing');
    };

    const handleReferralCodesClick = () => {
        //console.log("Referral codes clicked");
        setCurrentScreen('referralCodes');
    };

    const handleSkip = () => {
        //console.log("Claim or Skip button clicked");
        //console.log("Is Onboarding Complete:", isOnboardingComplete);
        if (isOnboardingComplete) {
            //console.log("Setting screen to landing");
            setCurrentScreen('landing');
        } else {
            //console.log("Setting screen to onboarding");
            setCurrentScreen('onboarding');
        }
    };

    const renderSkipButton = () => (
        <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 z-50 w-4/5 max-w-md">
            <button
                onClick={handleSkip}
                className="w-full p-4 rounded-xl bg-[#00E6CA] text-black font-semibold transition-all duration-200 hover:bg-[#00E3FA] disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap text-sm sm:text-base"
                disabled={!isDataLoaded}
            >
                {isOnboardingComplete ? " 💥 SKIP 💥" : "💥  CLAIM 1000 POINTS NOW 💥"}
            </button>
        </div>
    );

    //console.log("Rendering screen:", currentScreen);

    if (currentScreen === 'loading') {
        return (
            <div className="relative h-screen">
                <OpeningAnimation onComplete={handleAnimationComplete} />
                {renderSkipButton()}
            </div>
        );
    }

    switch (currentScreen) {
        case 'onboarding':
            //console.log("Rendering Onboarding component");
            return <Onboarding onComplete={handleOnboardingComplete} telegramInfo={telegramInfo!} />;
        case 'referralCodes':
            //console.log("Rendering ReferralCodesScreen component");
            return <ReferralCodesScreen
                referralCodes={referralCodes}
                onReadyClick={() => setCurrentScreen('landing')}
            />;
        case 'landing':
            //console.log("Rendering Landing component");
            return <Landing
                onReferralCodesClick={handleReferralCodesClick}
                showReferralCodesButton={isOnboardingComplete}
            />;
        default:
            //console.log("No matching screen found, rendering null");
            return null;
    }
};

//console.log("Rendering App component");
ReactDOM.render(
    <React.StrictMode>
        <ErrorBoundary>
            <App />
        </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('root')
);