import React, { useState, useEffect } from 'react';
const logoUrl = require('../assets/images/logo.png');
const topImage = require('../assets/images/top.png');
const bottomImage = require('../assets/images/bottom.png');


const words = ['BASED', 'RICH', 'REKT', 'A LEGEND', 'AWESOME', 'SPECIAL', 'EARNING', 'READY'];

const ANIMATION_DURATION = 5500; // 5 seconds total animation time
interface OpeningAnimationProps {
    onComplete: () => void;
}
const OpeningAnimation: React.FC<OpeningAnimationProps> = ({ onComplete }) => {
    const [currentWord, setCurrentWord] = useState(0);
    //const [animationComplete, setAnimationComplete] = useState(false);

    useEffect(() => {
        const wordInterval = setInterval(() => {
            setCurrentWord((prev) => {
                if (prev === words.length - 1) {
                    clearInterval(wordInterval);
                    return prev;
                }
                return prev + 1;
            });
        }, ANIMATION_DURATION / words.length);

        const animationTimer = setTimeout(() => {
            onComplete();
        }, ANIMATION_DURATION);

        return () => {
            clearInterval(wordInterval);
            clearTimeout(animationTimer);
        };
    }, [onComplete]);
    return (
        <div className="flex flex-col items-center justify-between h-screen bg-black text-white p-6">
            {/* Top header */}
            <div className="absolute top-4 right-4">
                <img src={topImage} alt="Top decoration" className="w-16 h-auto" />
            </div>

            <div className="flex flex-col items-center justify-center flex-grow">
                <div className="text-4xl font-bold mb-4">
                    <span className="mr-2">I AM</span>
                    <span className={`text-[#00E6CA] ${currentWord === words.length - 1 ? 'animate-glow-intense' : 'default-glow'}`}>
                        {words[currentWord]}
                    </span>
                </div>

                <img src={logoUrl} alt="iA4 BASED" className="mt-8 w-24" />
            </div>

            {/* Bottom footer */}
            <div className="absolute bottom-4 left-4">
                <img src={bottomImage} alt="Bottom decoration" className="w-24 h-auto" />
            </div>
        </div>
    );
};

export default OpeningAnimation;