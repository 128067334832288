import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import './src/styles.css';
import ReferralCodesScreen from './ReferralCodesScreen';
const logoUrl = require('../assets/images/logo.png');
const topImage = require('../assets/images/top.png');
const bottomImage = require('../assets/images/bottom.png');
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
declare global {
    interface Window {
        Telegram: any;
    }
}

const words = ['BASED', 'RICH', 'REKT', 'A LEGEND', 'AWESOME', 'SPECIAL', 'EARNING', 'READY'];


interface OnboardingProps {
    onComplete: (codes: string[]) => void;
    telegramInfo: { username: string, chatId: string };
}
const Onboarding: React.FC<OnboardingProps> = ({ onComplete, telegramInfo }) => {
    const [animationComplete, setAnimationComplete] = useState(false);
    const [currentWord, setCurrentWord] = useState(0);
    const [task1Complete, setTask1Complete] = useState(false);
    const [task2Complete, setTask2Complete] = useState(false);
    const [referralCodes, setReferralCodes] = useState<string[]>([]);
    const [zoomEffect, setZoomEffect] = useState(false);
    const [showCongrats, setShowCongrats] = useState(false);
    const [tweetLink, setTweetLink] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [hasTweeted, setHasTweeted] = useState(false);
    const [hasFollowed, setHasFollowed] = useState(false);

    const [allTasksComplete, setAllTasksComplete] = useState(false);
    const [isInputVisible, setIsInputVisible] = useState(false);

    const inputRef = useRef<HTMLInputElement>(null);


    useEffect(() => {
        if (task2Complete && !hasTweeted) {
            setIsInputVisible(true);
        }
    }, [task2Complete, hasTweeted]);

    useEffect(() => {
        const handleFocus = () => {
            // Delay to allow keyboard to appear
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 100);
        };

        inputRef.current?.addEventListener('focus', handleFocus);

        return () => {
            inputRef.current?.removeEventListener('focus', handleFocus);
        };
    }, []);


    useEffect(() => {
        if (telegramInfo) {
            checkUserStatus();
        }
    }, [telegramInfo]);

    const checkUserStatus = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/api/check-user-status?username=${telegramInfo.username}&chatID=${telegramInfo.chatId}`);
            if (response.ok) {
                const data = await response.json();
                setHasFollowed(data.hasFollowed);
                setHasTweeted(data.hasTweeted);
                if (data.hasFollowed && data.hasTweeted && data.refCodes) {
                    setReferralCodes(data.refCodes);
                    setAllTasksComplete(true);
                }
            } else {
                console.error('Failed to check user status');
            }
        } catch (error) {
            console.error('Error checking user status:', error);
        }
    };

    const handleTwitterFollow = async () => {
        window.open('https://twitter.com/IAMBASEDME', '_blank');
        setIsSubmitting(true);
        try {
            const response = await fetch(`${API_BASE_URL}/api/save-follow-status`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    telegramChatId: telegramInfo.chatId,
                    telegramUsername: telegramInfo.username,
                }),
            });

            if (response.ok) {
                const data = await response.json();
                if (data.success) {
                    setHasFollowed(true);
                    setTask1Complete(true);
                } else {
                    alert('THIS?? Failed to save follow status. Please try again.');
                }
            } else {
                alert('Failed to save follow status. Please try again.');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleTwitterPost = () => {
        //Text to Change
        // const tweetText = encodeURIComponent(
        //     `I Just scored 5 exclusive invite codes for the next big thing: IAMBASED.ME! Hit me up ASAP to get in on this epic new app! 💎👑 Claim your free invite codes before it's too late 🏃‍♂️💨- https://t.me/IAMBASED_Bot`
        // );
        const tweetText = encodeURIComponent(
            `I just scored 5 exclusive invite codes for the next big thing: IAMBASEDME! Hit me up ASAP to get in on this epic new app! 💎👑 Claim your free invite codes before it's too late 🏃‍♂️💨- https://t.me/iambasedme_bot`
        );
        window.open(`https://twitter.com/intent/tweet?text=${tweetText}`, '_blank');
        setTask2Complete(true);
    };

    const handleTwitterSubmit = async () => {
        if (!isValidTweetLink(tweetLink)) {
            alert("Please enter a valid Twitter/X link");
            return;
        }

        setIsSubmitting(true);

        try {
            const response = await fetch(`${API_BASE_URL}/api/save-tweet`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    tweetLink,
                    telegramChatId: telegramInfo?.chatId,
                    telegramUsername: telegramInfo?.username,
                }),
            });

            if (response.ok) {
                const data = await response.json();
                if (data.unique) {
                    setHasTweeted(true);
                    alert(data.message);
                    // Check user status again to get referral codes
                    await checkUserStatus();
                } else {
                    alert(data.message);
                }
            } else {
                alert("Failed to save tweet link. Please try again.");
            }
        } catch (error) {
            console.error('Error:', error);
            alert("An error occurred. Please try again.");
        } finally {
            setIsSubmitting(false);
        }
    };


    const isValidTweetLink = (link: string) => {
        return link.startsWith('https://twitter.com/') || link.startsWith('https://x.com/');
    };


    useEffect(() => {
        if (allTasksComplete && referralCodes.length > 0) {
            onComplete(referralCodes);
        }
    }, [allTasksComplete, referralCodes, onComplete]);

    if (allTasksComplete) {
        return null;  // Return null instead of ReferralCodesScreen
    }

    return (
        <div className="flex flex-col min-h-screen bg-black text-white p-4 relative">
            <div className="flex justify-between items-center mb-8">
                <span className="text-xl">IAMBASED</span>
                <img src={topImage} alt="Top decoration" className="w-16 h-auto" />
            </div>

            <div className="flex-grow flex flex-col items-center pb-20">
                <img src={logoUrl} alt="iA4 BASED" className="w-24 mb-8" />

                <p className="text-center mb-4">
                    🔥 Your ultimate polling app integrated with Telegram! EARN points for sharing your opinion and participating in HOT topics!
                </p>

                <p className="text-center mb-8">
                    💰 Claim your 5 unique invitation codes now and get ready to earn points!
                </p>

                <h2 className="text-xl font-bold mb-4">Complete These Steps:</h2>

                <div className="w-full space-y-4 mb-8">
                    <button
                        onClick={handleTwitterFollow}
                        disabled={hasFollowed}
                        className="w-full p-4 rounded-xl bg-[#82D3E0] text-black font-semibold transition-all duration-200 hover:bg-[#00E3FA] disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                        1. Follow @IAMBASEDME on X
                    </button>
                    <button
                        onClick={handleTwitterPost}
                        disabled={!hasFollowed}
                        className="w-full p-4 rounded-xl bg-[#82D3E0] text-black font-semibold transition-all duration-200 hover:bg-[#00E3FA] disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                        2. Post on X to claim your codes
                    </button>
                </div>

                {task2Complete && !hasTweeted && (
                    <div className="w-full space-y-4">
                        <input
                            ref={inputRef}
                            type="text"
                            value={tweetLink}
                            onChange={(e) => setTweetLink(e.target.value)}
                            placeholder="Paste your tweet link here"
                            className="w-full p-3 rounded-xl bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#82D3E0]"
                        />
                        <button
                            onClick={handleTwitterSubmit}
                            disabled={!isValidTweetLink(tweetLink) || isSubmitting}
                            className={`w-full p-4 rounded-xl text-black font-semibold transition-all duration-300
                                ${isValidTweetLink(tweetLink) && !isSubmitting
                                    ? 'bg-[#82D3E0] hover:bg-[#00E3FA]'
                                    : 'bg-gray-600 opacity-50 cursor-not-allowed'
                                }`}
                        >
                            <span className="flex items-center justify-center">
                                {isSubmitting ? 'Submitting...' : 'Verify Tweet'}
                            </span>
                        </button>
                    </div>
                )}
            </div>

            <div className="absolute bottom-4 left-4">
                <img src={bottomImage} alt="Bottom decoration" className="w-24 h-auto" />
            </div>
        </div>
    );
};

export default Onboarding;