import React from 'react';


const partyImage = require('../assets/images/party.png');
const topImage = require('../assets/images/top.png');
const bottomImage = require('../assets/images/bottom.png');
interface ReferralCodesScreenProps {
    referralCodes: string[];
    onReadyClick: () => void;
}

const ReferralCodesScreen: React.FC<ReferralCodesScreenProps> = ({ referralCodes, onReadyClick }) => {
    const handleReferralCodeClick = (code: string) => {
        const tweetText = encodeURIComponent(`Hey! I've got an exclusive invite code for you to join @IAMBASEDME - the hottest new polling app! 🔥\n\nUse my code: ${code}\n\nJoin now and let's earn points together! https://t.me/iambasedme_bot 💰 #IAMBASEDME`);
        const twitterShareUrl = `https://twitter.com/intent/tweet?text=${tweetText}`;
        window.open(twitterShareUrl, '_blank');
    };

    return (
        <div className="flex flex-col min-h-screen bg-black text-white font-barlow p-6 relative">
            <div className="flex justify-between items-center mb-8">
                <span className="text-xl">IAMBASED</span>
                <img src={topImage} alt="Top decoration" className="w-16 h-auto" />
            </div>

            <div className="flex-grow flex flex-col items-center justify-center">
                <img src={partyImage} alt="Celebration" className="w-16 h-16 mb-4" />
                <h1 className="text-2xl font-bold text-center mb-2">
                    Congratulations! You completed all steps needed.
                </h1>

                <div className="w-full text-left mb-8">
                    <p className="text-gray-400">
                        Here are your 5 invitation codes.<br />
                        Copy and send away!
                    </p>
                </div>

                {referralCodes.map((code, index) => (
                    <button
                        key={index}
                        onClick={() => handleReferralCodeClick(code)}
                        className="w-full bg-gray-800 text-[#9EEFFD] py-3 px-4 rounded-lg mb-3 flex justify-between items-center" // Updated text color
                    >
                        <span>{code}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
                        </svg>
                    </button>
                ))}

                <button
                    onClick={onReadyClick}
                    className="w-full bg-[#82D3E0] text-black font-bold py-4 rounded-lg mt-8 mb-16" // Updated button color
                >
                    I AM READY!
                </button>
            </div>

            <div className="absolute bottom-0 left-4">
                <img src={bottomImage} alt="Bottom decoration" className="w-24 h-auto" />
            </div>
        </div>
    );
};

export default ReferralCodesScreen;