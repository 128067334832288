import React from 'react';

const logoUrl = require('../assets/images/logo.png');
const topImage = require('../assets/images/top.png');
const bottomImage = require('../assets/images/bottom.png');

interface LandingProps {
    onReferralCodesClick: () => void;
    showReferralCodesButton: boolean;
}

const Landing: React.FC<LandingProps> = ({ onReferralCodesClick, showReferralCodesButton }) => {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-black text-white p-6">
            {/* Top header */}
            <div className="w-full flex justify-between items-center mb-8">
                <span className="text-xl">IAMBASED</span>
                <img src={topImage} alt="Top decoration" className="w-16 h-auto" />
            </div>

            {/* Main content */}
            <div className="flex-grow flex flex-col items-center justify-center">
                <img src={logoUrl} alt="IAMBASED.ME" className="w-32 mb-8" />
                <p className="text-xl mb-8 text-center">
                    Your ultimate polling app integrated with Telegram!
                </p>
                {showReferralCodesButton && (
                    <button
                        onClick={onReferralCodesClick}
                        className="bg-[#82D3E0] text-black font-semibold py-3 px-6 rounded-xl hover:bg-[#6BC1CF] transition-colors duration-300"
                    >
                        View Referral Codes
                    </button>
                )}
            </div>

            {/* Bottom footer */}
            <div className="absolute bottom-0 left-4">
                <img src={bottomImage} alt="Bottom decoration" className="w-24 h-auto" />
            </div>
        </div>
    );
};

export default Landing;